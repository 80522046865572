.contact {
  background-color: white;
  padding: 5rem 2rem;
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.contact-info {
  color: var(--secondary);
}

.contact-info h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.contact-info p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.contact-details {
  margin-top: 2rem;
}

.contact-details div {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-form {
  background-color: var(--tertiary);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--secondary);
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--primary);
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: var(--primary);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-button:hover:not(:disabled) {
  background-color: var(--secondary);
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-status {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
}

.submit-status.success {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
}

.submit-status.error {
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
}

@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact-info h2 {
    font-size: 2rem;
  }
}
