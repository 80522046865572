.about {
  background-color: var(--tertiary);
  padding: 5rem 2rem;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-header {
  text-align: center;
  margin-bottom: 4rem;
}

.about-header h2 {
  color: var(--secondary);
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
}

.about-header p {
  color: var(--primary);
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
}

.about-section {
  margin-bottom: 3rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.about-section h3 {
  color: var(--secondary);
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.about-section p {
  color: var(--primary);
  font-size: 1.1rem;
  line-height: 1.8;
  max-width: 900px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
}

.feature-card {
  background-color: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-card h3 {
  color: var(--secondary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.feature-card h3::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: var(--primary);
  margin-top: 0.5rem;
}

.feature-card p {
  color: var(--primary);
  line-height: 1.7;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .about {
    padding: 3rem 1rem;
  }

  .about-header h2 {
    font-size: 2.2rem;
  }

  .about-section h3 {
    font-size: 1.8rem;
  }

  .about-section {
    padding: 1.5rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .feature-card {
    padding: 2rem;
  }
}
